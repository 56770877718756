<template>
  <v-list-tile :class="notification.readAt === null ? 'unread list-item' : 'list-item'">
    <v-list-tile-avatar @click.native.prevent="gotoExams">
      <CourseIcon :icon="notification.course.icon"/>
    </v-list-tile-avatar>

    <v-list-tile-content
      @click.prevent="gotoExams"
    >
      <v-list-tile-title>{{notification.course.name}}</v-list-tile-title>
      <v-list-tile-sub-title>{{notification.message}}</v-list-tile-sub-title>
    </v-list-tile-content>

    <v-list-tile-action>
      <slot name="menu"></slot>
    </v-list-tile-action>
  </v-list-tile>
</template>

<script>
  import CourseIcon from '@/components/ui/course/Icon'
  import Routes from '@/router/routes'

  export default {
    name: "ExamDo",
    components:{
      CourseIcon,
    },
    methods: {
      gotoExams: function(){
        let me = this;
        me.$emit('read');
        me.$router.push({
          name: Routes.get('examLanding').name,
          params: {
            courseId: me.notification.course.id
          }
        })
      }
    },
    props:{
      notification:{
        type: Object,
        required: true
      }
    }
  }
</script>

<style scoped>
@import '../style/list-style.scss';
</style>
