<template>
  <div>
    <v-content v-if="true === loading && 0 === list.length" class="pa-0 ma-0 text-xs-center">
      <v-progress-circular
        class="mt-5"
        color="primary"
        indeterminate
      />
    </v-content>
      <v-list two-line class="scroll-y" :style="'height:' + height">
        <SwitcherComponent
          v-if="list.length > 0"
          v-for="notification in list"
          :key="notification.id"
          :notification="notification"
          @read="read(notification)"
          @remove="remove(notification)"
        />
      </v-list>

    <v-content
      v-if="false === loading && 0 === list.length"
      class="list-item"
    >
      <v-list>
        <v-list-tile>
          <v-list-tile-avatar>
            <v-icon color="primary ligthen-3">done</v-icon>
          </v-list-tile-avatar>

          <v-list-tile-content>
            No tienes notificaciones por el momento
          </v-list-tile-content>
        </v-list-tile>
      </v-list>
    </v-content>

  </div>
</template>

<script>
  import ContextMenu from '../_components/ContextMenu'
  import SwitcherComponent from '../_components/Switcher'
  import {mapGetters} from 'vuex'

  export default {
    name: "Notification",
    components:{
      SwitcherComponent,
      ContextMenu
    },
    data(){
      return {
        unreadTrack: 0
      }
    },
    computed:{
      ...mapGetters({
        loading: 'services/api/notification/fetching',
        list: 'services/api/notification/list',
        paginator: 'services/api/notification/paginator'
      })
    },
    methods: {
      onScroll: function(ev){
        let me = this,
          t = ev.target,
          offset = t.scrollTop + t.clientHeight,
          height = t.offsetHeight;

        if(offset > height){
          me.fetch();
        }
      },
      fetch: function(){
        let me = this;

        if(true === me.loading){
          return;
        }

        me.$store.dispatch('services/api/notification/fetch');
      },
      remove: function(notification){
        let me = this;
        me.$store.dispatch('services/api/notification/remove', notification);
      },
      read:function(notification){
        let me = this;
        me.$store.dispatch('services/api/notification/read', notification)
      }
    },
    props:{
      height:{
        type: [String, Number],
        default: '62vh'
      }
    }
  }
</script>

<style scoped>
  @import '../_components/style/list-style.scss';
</style>
