<template>
  <VImg
    v-if="found"
    :src="icon"
    :contain="contain"
    :class="classes"
    :aspect-ratio="aspectRatio"
    :width="width"
    :height="height"
    v-on:error="found = false"
  />
  <VImg
    v-else
    :src="$courseIconUrl"
    :contain="contain"
    :class="classes"
    :aspect-ratio="aspectRatio"
    :width="width"
    :height="height"
  />
</template>

<script>
  import VImg from 'vuetify/lib/components/VImg'

  export default {
    name: "CourseIcon",
    data(){
      return{
        found: true,
      }
    },
    mounted() {
      VImg.extend({
        methods: {
          onError() {
            console.log("Error on load image: ", this.icon);
          }
        }
      });
    },
    props:{
      icon: {
        type: String,
        required: true
      },
      contain:{
        type: Boolean,
        default: false,
      },
      classes:{
        type: String,
        default: ''
      },
      aspectRatio:{
        type: [String, Number],
        default: ""
      },
      width:{
        type: [String, Number],
        default: ""
      },
      height:{
        type: [String, Number],
        default: ""
      }
    }
  }
</script>
