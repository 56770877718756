<template>
  <v-menu offset-y>

    <v-icon ripple slot="activator">more_vert</v-icon>

    <v-list>

      <v-list-tile @click.prevent="remove(notification)">
        <v-list-tile-avatar>
          <v-icon>fas fa-trash</v-icon>
        </v-list-tile-avatar>

        <v-list-tile-title>
          Eliminar
        </v-list-tile-title>
      </v-list-tile>

    </v-list>
  </v-menu>
</template>

<script>
  export default {
    name: "ContextMenu",
    methods:{
      remove: function(notification){
        let me = this;
        return me.$emit('remove', notification);
      }
    },
    props:{
      notification:{
        type: Object
      }
    }
  }
</script>

<style scoped>

</style>
