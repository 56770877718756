<template>
  <v-list-tile :class="notification.readAt === null ? 'unread list-item' : 'list-item'">
    <v-list-tile-avatar>
      <v-icon color="primary">ico-thumbs-up</v-icon>
    </v-list-tile-avatar>

    <v-list-tile-content
      @click.prevent="gotoCourseCalendar"
    >
      <v-list-tile-title>{{notification.calendar.course.name}}</v-list-tile-title>
      <v-list-tile-sub-title>{{notification.message}}</v-list-tile-sub-title>
    </v-list-tile-content>


    <v-list-tile-action>
      <slot name="menu"></slot>
    </v-list-tile-action>
  </v-list-tile>
</template>

<script>

  import Routes from '@/router/routes'

  export default {
    name: "SurveyThanks",
    methods:{
      gotoCourseCalendar: function(){
        let me = this;
        me.$emit('read');
        me.$router.push({
          name: Routes.get('course').name,
          params:{
            course: me.notification.calendar.course.id,
            calendar: me.notification.calendar.id
          }
        })
      }
    },
    props:{
      notification:{
        type: Object,
        required: true
      }
    }
  }
</script>

<style scoped>
  @import '../style/list-style.scss';
</style>
