<template>
  <div>
    <SurveyThanks
      v-if="'survey_thanks' === notification.type.name"
      :notification="notification"
      @read="read(notification)"
    >
      <ContextMenu
        slot="menu"
        :notification="notification"
        @read="read"
        @remove="remove"
      />
    </SurveyThanks>

    <ExamDo
      v-if="'exam_do' === notification.type.name || 'certification_do' === notification.type.name"
      :notification="notification"
      @read="read"
    >
      <ContextMenu
        slot="menu"
        :notification="notification"
        @read="read"
        @remove="remove"
      />
    </ExamDo>

    <SurveyDo
      v-if="'survey_do' === notification.type.name"
      @read="read"
      :notification="notification"
    >
      <ContextMenu
        slot="menu"
        :notification="notification"
        @read="read"
        @remove="remove"
      />
    </SurveyDo>

    <RecoveryClass
      v-if="'calendar_class_recovery' === notification.type.name"
      @read="read"
      :notification="notification"
    >
      <ContextMenu
        slot="menu"
        :notification="notification"
        @read="read"
        @remove="remove"
      />
    </RecoveryClass>

  </div>
</template>

<script>
  import ContextMenu   from './ContextMenu'
  import SurveyThanks  from './type/SurveyThanks';
  import SurveyDo      from './type/SurveyDo';
  import ExamDo        from './type/ExamDo'
  import RecoveryClass from './type/RecoveryClass'

  export default {
    components:{
      ContextMenu,
      ExamDo,
      SurveyThanks,
      SurveyDo,
      RecoveryClass
    },
    name: "Notification",
    methods:{
      remove:function(){
        let me = this;
        return me.$emit('remove', me.notification);
      },
      read: function(){
        let me = this;
        return me.$emit('read', me.notification)
      }
    },
    props:{
      notification:{
        type: Object,
        required: true
      }
    }
  }
</script>

<style scoped>

</style>
