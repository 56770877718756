<template>
</template>

<script>
  import moment from 'moment-timezone'

  export default{
    methods:{
      parseDateTextualMixin: function(date, parseTime=true, timezone='GMT-3'){
        let format = parseTime ? "DD-MM-YYYY HH:mm:ss" : "DD-MM-YYYY";

        let parsed = moment.tz(
          new Date(Date.parse(date+' '+timezone)),
          format,
          'America/Argentina/Buenos_Aires'
        );

        return parsed.format("dddd, MMM DD YYYY " + (true === parseTime ? 'HH:mm:ss' : ''))
        .replace(
          /^(.)|\s+(.)/g, ($1) => {
            return $1.toUpperCase()
          }
        );
      },
      parseDateDMYFormatMixin: function(date, parseTime=true){
        let format = parseTime ? "DD-MM-YYYY HH:mm:ss" : "DD-MM-YYYY",
          parsed = moment(
            new Date(date),
            format
          );

        return parsed.format("DD/MM/YYYY " + (true === parseTime ? 'HH:mm:ss' : ''))
          .replace(
            /^(.)|\s+(.)/g, ($1) => {
              return $1.toUpperCase()
            }
          );
      }
    }
  }
</script>
