<template>
  <v-list>

    <v-list-item @click="logout" class="list-item">

      <v-list-item-action>
        <v-icon color="error">fas fa-power-off</v-icon>
      </v-list-item-action>

      <v-list-item-content >
        <v-list-item-title>Salir</v-list-item-title>
      </v-list-item-content>

    </v-list-item>

  </v-list>
</template>

<script>
import Store from '@/store';

export default {
  name: "ProfileMenu",
  props:{
    user: Object,
    required: true
  },
  methods: {
    async logout(){
      const firebaseAuth = Store.state.AuthFirebase.auth
      const logoutAction = await firebaseAuth ? 'AuthFirebase/logOut' : 'services/api/auth/logout'

      Store.dispatch(logoutAction)
        .then(() => this.$router.push({ name: "Login" }))
    }
  }
}
</script>

<style scoped>

.list-item:hover .icon-profile {
  -webkit-animation: swing 1s ease;
  animation: swing 1s ease;
  -webkit-animation-iteration-count: 1;
  animation-iteration-count: 1;
  color:#fff;
}

.list-item:hover .icon-setting {
  -webkit-animation: spin 1s infinite linear;
  animation: spin 1s infinite linear;
  color:#fff;
}

.list-item:hover .icon-logout{
  -webkit-animation: fadeIn 1s;
  animation: fadeIn 1s;
  color:#fff;
}
</style>
