<template>
  <v-app-bar
    dark
    color="primary"
    :app="app"
    class="elevation-0 main-nav"
    height="50"
  >
    <!-- <v-icon class="hamburger" @click.prevent="toggleSidebar">fas fa-bars</v-icon> -->

    <router-link to="/dashboard">
      <v-app-bar-nav-icon class="text-xs-center ml-3">
        <img src="@/assets/logo/logo.svg" width="32" style="margin:0 10px 0 25px;-webkit-filter: brightness(10);filter: brightness(10);"/>
      </v-app-bar-nav-icon>
      <span class="white--text pa-2">TEACHER</span>
    </router-link>

    <v-spacer></v-spacer>

    <!-- User Profile toggle button -->
    <v-menu
      ref="profileMenu"
      offset-y
      min-width="300"
      max-width="300"
      :close-on-content-click="false"
      justify-end
      class="hidden-sm-and-down"
    >

      <template v-slot:activator="{on}">
        <v-btn
          slot="activator"
          icon
          v-on="on"
        >
          <v-avatar :size="30">
            <img :src="avatar" />
          </v-avatar>
        </v-btn>
      </template>

      <v-list>
        <v-list-item>
          <v-list-item-icon>
            <v-icon color="primary lighten-3" class="animated pulse infinite">fas fa-user</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>{{user.name}} {{user.lastname}}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-divider />

        <ProfileMenu
          ref="profileMenu"
          :user="user"
        />
      </v-list>
    </v-menu>
  </v-app-bar>
</template>

<script>
  import NotificationModule from '@/modules/notification/mobile'
  import ProfileMenu from '@/modules/profile/menu'

  import TranslationMixin from '@/mixins/Translation'
  import { mapGetters } from 'vuex'
  import uniqid from 'uniqid'

  export default {
    name: 'Toolbar',
    data(){
      return{
        buttonId: null,
        isMounted: false
      }
    },
    mixins:[
      TranslationMixin
    ],
    components:{
      NotificationModule,
      ProfileMenu
    },
    computed:{
      ...mapGetters({
        fetchingNotifications: 'services/api/notification/fetching',
        notifications: 'services/api/notification/list',
        unreadNotifications: 'services/api/notification/unreadCount',
        user: 'services/api/auth/user',
        avatar: 'services/api/auth/avatar',
        isMobile: 'services/browser/window/mobile',
        windowHeight: 'services/browser/window/height'
      })
    },
    created(){
      let me = this;
      me.buttonId = uniqid('button-');
    },
    mounted(){
      let me = this;
    },
    methods:{
      toggleSidebar: function(){
        let me = this;
        me.$root.$emit('toggleSidebar');
      }
    },
    props:{
      app:{
        type:Boolean,
        default: true,
        required: false
      }
    }
  }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
  nav{
    padding:0px !important;
  }

  .hamburger{
    cursor:pointer;
  }

  nav a{
    color:#fff !important;
    text-decoration:none;
  }

  nav .toolbar__title a img{
    width:30px;
    display:block;
  }

  .notification-menu-mobile{
    top: 0 !important;
    left: 0 !important;
    min-width: 100% !important;
    position: absolute;
    height: 100vh;
    background-color:#fff;
  }

  .notification-menu{
    min-width: 600px;
    max-width: 600px;
    background-color:#fff;
  }

</style>
